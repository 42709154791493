// All element types color
$main-color: #001aff;
$seconday-color: #e64825;

// Links
$link-color1: #1a0dab;
$link-color2: #72737f;

// Elements with gradients
$grad-main-color-1: #001aff;
$grad-main-color-2: #0096ff;

$grad-secondary-color-1: #e64825;
$grad-secondary-color-2: #e68225;

// Text elements
$text-color-primary: #1b1b1b;
$text-color-secondary: #ffffff;
$text-color-third: #6b6b6b;

// ---
$placeholder-color-primary: #c8c8c8;

// Backgrounds
$bg-color-primary: #fefefe;
$bg-color-secondary: #e1eff6;
$bg-color-3: #0086ec;
$bg-color-4: #f7f7f7;
$bg-color-5: #f5f5f5;

$bg-table-header: #e7e7e7;
$bg-table-row-selected: #e7c8ff;
$bg-menubar-color1: #e7e7e7;

// Borders
$border-color1: #c5c5c5;
$border-color2: #818181;
$border-color3: #d7d7d7;

$border-radius-1: 4px;
$border-radius-2: 6px;
// ---
$divider-color-1: #c5c5c5;

// ---
$header-height: 60px;
$header-margin-bottom: 7px;

$side-body-width : 20%;
$main-body-width : 80%;

// Annuler normal text the same on both events // no hover #e7e7e7 no border || on hover #c5c5c5

// Signal colors
$error-color: #FD5758;
$validate-color: #34A853;
