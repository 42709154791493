@import '../../variables';
.position{
    position: fixed;
    bottom: 5%; 
    z-index: 1000;
    left: 2%;
    // transform: translate(50%, 50%);
    height: fit-content;
    width: fit-content;
}
#message{
    font-weight: bold;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: fit-content;
    height: fit-content;
}
.closebtn {
    margin-left: 15px;
    color: rgb(133, 133, 133);
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .closebtn:hover {
    color: #242424;
  }
// .container_green{
//     display: flex;
//     align-items: center;
//     padding-right: 10px;
//     padding-left:10px ;
//     min-height: 50px;
//     max-height: fit-content;
//     min-width: 400px;
//     max-width: fit-content;
//     border-radius: 5px;
//     border-top: 1px solid #50DC6C;
//     border-right: 1px solid #50DC6C;
//     border-bottom: 1px solid #50DC6C;
//     border-left: 7px solid #50DC6C;
//     background: #F1F8F4;
// }

.container_red{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left:10px ;
    min-height: 50px;
    max-height: fit-content;
    min-width: 400px;
    max-width: fit-content;

    border-radius: 5px;
    border-top: 1px solid #00ff15;
    border-right: 1px solid #00ff15;
    border-bottom: 1px solid #00ff15;
    border-left: 7px solid #00ff15;
    background: #FAEFEB;
    opacity: 0;
    animation: fadeIn 500ms ease-in-out forwards;
}


  .destroy{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left:10px ;
    min-height: 50px;
    max-height: fit-content;
    min-width: 400px;
    max-width: fit-content;

    border-radius: 5px;
    border-top: 1px solid #00ff15;
    border-right: 1px solid #00ff15;
    border-bottom: 1px solid #00ff15;
    border-left: 7px solid #00ff15;
    background: #FAEFEB;
    opacity: 1;
    animation: destroy 500ms ease-in-out forwards;
  }

  @keyframes destroy {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0; 
    }
    to {
      opacity: 1; 
    }
  }

