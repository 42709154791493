@import '../../../variables';

.container {
    padding: 0 25px;
}

.upper-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
}

.set-in-row {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.users-num {
    color: $error-color;
    font-size: 2em;
    font-weight: 500;
}

.employees {
    font-weight: 600;
    font-size: 1.75em;
}

.search-and-add {
    column-gap: 22px;
}

.employees-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
}
