@import '../../variables';

 
.output-text-container{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    flex-grow: 1;
    height: 45px;
    padding: 10px;
    border: 2px solid #f9f9f9;
    background: #f9f9f9;
    border-radius: 4px;
    box-shadow: 0px 0px 2px $text-color-third;
}
.container{
    display: flex;
    align-items: center;
    width: 100%;
}
.label-container{
    width: 150px;
    font-size: 17px;
    font-weight: 600;
    white-space: nowrap;
}