@import '../../variables';

.page-container{
    padding: 4% 20% 4% 20%;
    width: 100%;
    height: 100%;
}

.tables-holder {
    display: flex;
    width: 100%;
}

.table-outer-container {
    position: relative;
    overflow-y: scroll;
    height: 540px;
    width: 50%;
    border: 2px solid #e7e7e7;
    border-radius: 4px;
    box-shadow: 0px 0px 2px #bdbdbd;
}

.table-container{
    width: 100%;
}

.table-head-container{
    background-color: #e7e7e7;
}

.table-head{
    text-align: start;
    font-weight: 600;
    font-size: 16px;
    padding-right: 10px;
    padding-left: 10px;
    height: 42px;
}

.table-body-row{
    text-align: start;
    height: 48px;
}

.table-column{
    border-right: 2px solid #e7e7e7;
    padding-right: 10px;
    padding-left: 10px;
}

.point-hover {
    cursor: pointer;
}

.table-button-row{
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7e7e7;
    height: 42px;
    width: 100%;
}

.table-button {
    width: 100%;
    color: $grad-main-color-2;
    font-weight: 600;
    font-size: 16px;
    padding-top: 3px;
}

.row-bg-1 {
    background-color: $bg-color-primary;
}

.row-bg-2 {
    background-color: #ebf7ff;
}

.selected-row {
    background-color: $bg-table-row-selected;
}

.add-family-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
    padding: 3%;
    border-radius: 4px;
    box-shadow: 0px 0px 2px #bdbdbd;
    margin-top: 10px;
    background: #ebf7ff;
}

// .stick {
//     // position: absolute;
//     bottom: 0;
//     z-index: 2;
//     // background-color: red;
// }
