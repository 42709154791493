.mod{
    display: flex;
    position: relative;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    background: white;
    box-shadow: -2px 2px rgb(211, 211, 211);
    border-radius: 5px;
}
.mod-name{
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
    height: fit-content;
}
.mod-items-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 496px;
    min-width: 496px;
    gap: 10px;
    height: fit-content;
    height: 100%;
    bottom: 0;
}