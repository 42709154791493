@import '../../variables';
@import '../../app.scss';

.container {
    display: flex;
    width: 100vw;
    height: calc(100vh - ($header-height + $header-margin-bottom));
    background-color: $bg-color-primary;
}

.upper-content-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.go-back {
    display: flex;
    align-items: center;
    gap: 10px;
}

.go-back p, .go-back span {
    font-weight: 600;
}

.side-page {
    background-color: $bg-color-3;
    height: 100%;
    width: 280px;
    border-radius: $border-radius-2;
    padding: 40px 15px;
}

.side-page * {
    width: 100%;
}

.title {
    font-weight: bold;
    color: $text-color-secondary;
    font-size: 2em;
    text-align: center;
    margin-bottom: 30px;
}

.header {
    color: $text-color-secondary;
    margin: 1.2em 0;
    font-size: 1.05em;
}

.page-name {
    color: $text-color-secondary;
    font-weight: 600;
    padding: 1.2em;
    text-align: start;
    font-size: 1.105em;
    border-radius: $border-radius-2;
    letter-spacing: .015em;
}

.page-name:hover {
    @include darkenBGColor($bg-color-3, 2.5%);
    transition: background-color 0.2s ease;
}

.content-side {
    width: 100%;
}

.active-btn {
    @include darkenBGColor($bg-color-3, 5%);
}
