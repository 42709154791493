@import './variables';

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('./Inter-VariableFont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

html {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

body {
    width: 100%;
    height: 100vh;
    background-color: #e1eff6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 .7px;
}

main {
    min-height: 100vh;
}

h1 {
    font-weight: bold;
    font-size: 2.5rem;
}

h1, h2, h3, h4, h5, h6, p, span {
    color: $text-color-primary;
}

@mixin lightenBGColor($color, $percentage) {
    background-color: lighten($color, $percentage);
}

@mixin darkenBGColor($color, $percentage) {
    background-color: darken($color, $percentage);
}
