@import '../../variables';
 
.dialog{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    padding: 50px 25px 50px 25px;
    border-radius: 10px;
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border: 1px solid black;
}
.dialog-table{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    padding: 50px 25px 50px 25px;
    border-radius: 10px;
    width: 500px;
    height: fit-content;
    position: absolute;
    align-self: center;
    z-index: 999;
    left: 10%;
    top: 25%;
    // bottom: 40%;
}
.grey-screen{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: black;
    z-index: 998;
    opacity: 50%;
}

.row-end{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
}

.noDisplay{
    width:100%;
    display: flex;
    align-items: center;
    gap: 20px;
    visibility: hidden;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
    z-index: 999;
}
.cancel:hover{
    cursor: pointer;
}
.cancel{
    width: 100%;
    height: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $divider-color-1;
    border-radius: 5px;
    color: black;
    font-weight: 700;
}
