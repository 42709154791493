@import '../../variables';
.article-details-container{
    display: grid;
    grid-template-columns:repeat(3 , 31%);
    gap: 2rem;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    padding: 24px 10px 10px 10px;
    justify-content: space-around;
    border-radius: 4px;
}
.table-details-container{
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    padding: 24px 10px 10px 10px;
    justify-content: space-around;
    border-radius: 4px;
    height: 100%;
}
.fiche-stock-produit-container{
    display: grid;
    grid-template-columns:repeat(4 , auto);
    gap: 2rem;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    padding: 30px 10px 10px 10px;
    border-radius: 4px;
}
.boldy{
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 5px;
}