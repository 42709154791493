@import '../../variables';
.tx-container{
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    height: fit-content;
}
.col-start{
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 5px;
    height: fit-content;
}
.row-end{
    display: flex;
    justify-content: end;
    flex-direction: row;
    gap: 0.5rem;
    
}
.row-start{
    display: flex;
    justify-content: start;
    flex-direction: row;
    gap: 0.5rem;
}
.row-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 5px;
}
.tx-container-total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 5px;
    height: fit-content;
}
.boldy{
    font-weight: 600;
}
.txt-third-clr{
    color: $text-color-third;
}
.side-tx-container {
    display:flex;
    width:fit-content;
}