@import '../../variables';

.input-text-container{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid $grad-main-color-1;
    background: $bg-color-primary;
}
// container class wheen error
.input-text-container_red{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid red;
    background: $bg-color-primary;
}
//
.input-text{
    font-size: 16px;
    width: 100%;
    height: 40px;
    padding-left: 15px;
    border-radius: 4px;
    border: none;
}
.input-text::placeholder{
    color: $text-color-third;
    font-weight: 600;
}
.search-icon{
    position: absolute;
    right: 0;
    margin-right: 15px;
    align-items: center;
}
.input-container{
    position: relative;
    height: fit-content;
}
.search-filter-display{
    width: 100%;
    height: fit-content;
    max-height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    background: rgb(255, 255, 255);
    top: 105%;
    z-index: 1;
    box-shadow: 0px 0px 2px black;
}
.search-filter-noDisplay{
    height: fit-content;
    display: none;
    position: absolute;
}
.search-items{
    font-size: 16px;
    padding: 15px;
}
.search-items:hover{
    background: $bg-table-header;
}
.custom-label{
    width: fit-content;
    align-items: flex-start ;
    height: fit-content;
    position: absolute;
    top: -27px;
}
// label text when error
.label-text_red{
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 3px;
    align-items: flex-start;
    color: red;
    font-weight: bold;
}
.label-text_red p{
    width: max-content;
}
//
.label-text{
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 3px;
    align-items: flex-start;
    color: #000000;
    font-weight: bold;
}
.label-text p{
    width: max-content;
}
.custom-label p{
    width: max-content;
}
.ajouter{
    color: $main-color;
    font-size: 16px;
}