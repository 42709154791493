@import '../../variables';

.header-container{
    width: 100%;
    height: 100%;
    padding: 2% 10% 2% 10%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    height: fit-content;
}
.input-container{
    width: 50%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.operations-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    height: fit-content;
}
.sub-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: fit-content;
}
.validation-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
}
.ajouter-caisse{
    display: flex;
    gap: 10px;
}
.noDisplay{
    display: none;
}
.suivie-charge{
    display: flex;
    align-items: center;
    height: 45px;
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
}
.check-input{
    height: 15px;
    width: 15px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center; 
}
.check-input:checked{
    background-size: cover;
    background-position: center center;
}
.check-input-container{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 15px;
}
.checkbox-name{
    font-size: 14px;
    font-weight: 600;
}
.checkbox-not-checked{
    background-color: rgb(218, 218, 218);
    border-radius: 4px;
    border: 2px dashed rgb(181, 181, 181);
    width: 100%;
    height: 45px;
}
.change-bg{
    height: 45px;
    width: 60%;
}