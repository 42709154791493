@import '../../variables';

.main-search-bar{
    margin-top: 25px;
    display: flex;
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.operations-buttons{
    display: flex;
    margin-right: 15px;
    gap: 10px;
    width: 45%;
    justify-content: flex-end;
}

.table{
    margin-top: 50px;
    width: 100%;
    height: 400px;
    padding-left: 15px;
    padding-right: 15px;
}