@import '../../_variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: $bg-color-5;
}

.message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 7%;
}

.error-code {
  font-size: 10rem;
  color: $main-color;
  margin-bottom: 15px;
}

.message {
  font-size: 3.6rem;
  color: $text-color-primary;
  font-weight: 600;
}
