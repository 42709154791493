@import '../../variables';

.container {
    width: 100vw;
    height: 100vh;
    background-color: $bg-color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-h1 {
    margin-bottom: 5px;
}

.extra-msg {
    margin-left: 3px;
    margin-bottom: 30px;
}

.form-container {
    min-width: 350px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-item {
    margin-bottom: 20px;
}

.form-item:last-child {
    margin-top: 25px;
}

.links-section {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 0 3px;
}

.links-section * {
    color: $link-color2;
    font-weight: 500;
    font-size: 0.94em;
}
