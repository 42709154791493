@import '../../variables';

.select-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    height: 44px;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid $grad-main-color-1;
    padding-bottom: 5px;
    gap: 1px;
    background: $bg-color-primary;
    padding-left: 15px;
}
.select-container-red {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    height: 44px;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid red;
    padding-bottom: 5px;
    gap: 1px;
    background: $bg-color-primary;
    padding-left: 15px;
}

.select-body-container {
    width: 100%;
}

.select-label {
    font-size: 16px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 3px;
    font-weight: bold;
}

.select {
    font-size: 16px;
    width: 100%;
}

.select-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 15px;
    margin-bottom: 15px;
    width: fit-content;
    height: fit-content;
    pointer-events: none;
}

.label {
    font-size: 16px;
    margin-bottom: 5px;
    align-items: flex-start;
    color: #242424;
    font-weight: bold;
    text-overflow: ellipsis;
}
.custom-label{
    font-size: 16px;
    margin-bottom: 5px;
    color: #242424;
    font-weight: bold;
    text-overflow: ellipsis;
    width: fit-content;
    align-items: flex-start ;
    position: absolute;
    top: -22px;
}
