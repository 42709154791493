@import '../../variables';

.balance-container{
    display: flex;
    flex-direction: column;
    padding: 3% 20% 13% 20%;
    gap: 25px;
}
.operations-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.balance{
    display: flex;
    gap: 4%;
}
.balance-line3{
    display: flex;
    justify-content: flex-end;
    gap: 4%;
}

