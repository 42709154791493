@import '../../variables';
.container{
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    height: fit-content;
    gap: 10px;
}
.container-lined{
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 25%;
    max-width: 100px;
    overflow-x:hidden ;
    gap: 10px;
}
.end{
    display: flex;
    justify-content: end;
    gap: 10px;
}
.container-lined p , .container p{
    font-weight:600;
}
