@import '../../variables';


.printing-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -100;
    display: flex;
    flex-direction: column;
    padding: 3% 3% 3% 3%;
    // transform: scale(0.93);
    gap: 5px;
}
@media print {
    .page-break {
        // margin-top: 200px;
        // display: block;
        page-break-after: always;
    }
    @page :first {
        margin-top: 0; /* Set margin-top to 0 for the first page */
    }
    @page { 
        size: A4;
        margin-top: 1.5rem; /* Adjust the margin as needed for subsequent pages */
    }
}
.printing-container-hide{
    display: none;
}
.header-container{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.logo-container{
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
}
.first-line{
    background-color: black;
    width: 100%;
    height: 1.5px;
}
.second-line{
    background-color: black;
    width: 100%;
    height: 0.5px;
}
.Line{
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.title-info{
    font-size: 23px;
    width: 100%;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.info-container{
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.sub-info-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.sub-title-info{
    font-size: 15px;
    width: 100%;
    letter-spacing: 0.5px;
    font-weight: 500;
}
.info-enterprise{
    font-size: 12px;
    width: 100%;
    letter-spacing: 0.5px;
    color: rgb(137, 136, 136);
}
.body-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    gap: 10px;
}
.main-title-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.title-number{
    font-size: 23px;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.bon-info{
    display: flex;
    height: fit-content;
    width: 100%;
    justify-content: space-between;
}
.info-container-type2{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px 0px 10px;
}
.head-lines-container{
    display: flex;
    width: fit-content;
    height: 100%;
    gap: 5px;
}
.head-lines-type1{
    width: fit-content;
    height: fit-content;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.head-lines-type2{
    width: fit-content;
    height: fit-content;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.head-lines-type3{
    width: fit-content;
    min-width: 90px;
    height: fit-content;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
}
.bar-code{
    display: flex;
    width: 250px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-color: black;
    border-style: dashed;
    border-width: 0.5px;
}
.table-container{
    width: 100%;
    height: 400px;
    // border-color: black;
    // border-style: solid;
    // border-width: 1px;
    overflow: hidden;
}
.table{
    border-collapse: collapse;
    min-height: 400px;
    width: 100%;
    overflow: hidden;
}
.col1 {
    border: 0.5px solid #000000; /* Apply border to first column */
}
.table-head{
    padding: 5px;
    // border-left: 0.5px solid black;
    border-bottom: 0.5px solid black;
    text-align: left;
    // width: 100%;
    font-weight: 600;
    &:first-child {
        border-left: transparent;
    }
}
.table-head-container{
    width: 100%;
}
.td-cell {
    height: fit-content;
    padding: 5px;
    // border-left: 0.5px solid black;
    font-size: 12px;

    &:first-child {
        border-left: transparent;
    }
}
.amounts-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.amounts-letters{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.amounts-numbers{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.head-lines-type6{
    width: fit-content;
    flex-grow: 1;
    min-width: 90px;
    height: fit-content;
    text-align: end;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
}
.head-lines-container-type2{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 5px;
}
.client-info{
    display: flex;
    flex-direction: column;
}
.client-info-container{
    display: flex;
    gap: 20px;
}
.head-lines-type4{
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.head-lines-type5{
    width: fit-content;
    min-width: 90px;
    height: fit-content;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
}