@import '../../variables';

.container {
    display: flex;
    flex-direction: column;
}

.upper-page {
    background-color: $bg-color-4;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    background-color: $bg-color-4;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 55px;
    border-bottom: 1px solid $border-color3;
}

.user-info-sec-1 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.profile-pic {
    width: 110px;
}

.uis-item-1 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 7px;
}

.uis-item-2 {
    font-weight: 600;
    font-size: 1.1em;
    color: $text-color-third;
    margin-bottom: 9px;
}

.uis-item-3 {
    font-weight: 600;
    margin-bottom: 7px;
}

.user-info-sec-2 {
    width: 34%;
}

.prms-header {
    font-size: 1.3em;
    font-weight: 600;
}

.prms-display {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 50%;
    margin: 20px 0;
    border: 1px dashed $border-color3;
    padding: 0 10px;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contact-info-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border: 1px solid $border-color3;
    border-radius: $border-radius-2;
    background-color: $bg-color-primary;
    padding: 9px 0;
}

.email {
    width: 55%;
}

.phone {
    width: 45%;
}
