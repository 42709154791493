.mods-container{
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
}
.mods-container-2-line{
    height: 100%;
    width: 100%;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}