@import '../../variables';
.body-container{
    padding: 50px 10% 25px 10%;
}
.main-body{ 
    height: fit-content;
    //margin:20px 0 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}
.row-end{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
}
.row-between{
    width:100%;
    height:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.row{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.check-input{
    height: 15px;
    width: 15px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center; 
}
.check-input:checked{
    background-size: cover;
    background-position: center center;
}
.check-input-container{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 15px;
}
.checkbox-name{
    font-size: 14px;
    font-weight: 600;
}
.checkbox-not-checked{
    background-color: rgb(218, 218, 218);
    border-radius: 4px;
    border: 2px dashed rgb(181, 181, 181);
    width: 100%;
    height: 45px;
}
