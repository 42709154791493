.container {
    padding: 16px;
}

.fill-width {
    width: 100%;
}
.table-height{
    width: 100%;
    height: 450px;
}

.upper-section {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
