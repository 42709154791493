@import '../../variables';

.container {
    overflow: auto;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    border: 2px solid $bg-table-header;
    border-radius: $border-radius-1;
}

.table-title {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
}

.custom-table {
    width: fit-content;
    height: fit-content;
    border-radius: $border-radius-1;
    margin: 0 auto;
}

.table-head-container {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: $bg-table-header;
    height: 32px;
    font-weight: 600;
    border-left: 2px solid $bg-table-header;
}

.table-head {
    display: flex;
    width: 100%;

    &:last-child {
        padding-left: 10px;
        padding-right: 16px;
    }
}

.row-bg-1 {
    background-color: $bg-color-primary;
}

.row-bg-2 {
    background-color: #ebf7ff;
}

.selected-row {
    background-color: $bg-table-row-selected;
}

.table-body-row {
    height: 32px;
}

.checkbox-col {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
}

.checkbox-icon {
    margin: 0 auto;
}

.td-cell {
    height: 100%;
    vertical-align: middle;
    justify-self: center;
    border-left: 2px solid $bg-table-header;

    &:first-child {
        border-left: transparent;
    }
}

.cell-padding {
    padding-left: 10px;
    padding-right: 10px;
}

.operation-container {
    position: sticky;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $bg-color-primary;
    border-top-left-radius: $border-radius-1;
    border-bottom-left-radius: $border-radius-1;
    height: 100%;
    width: fit-content;
    margin: auto 0;
    padding-left: 4px;
    padding-right: 4px;
    z-index: 9;
}

.operation {
    display: flex;
    align-items: center;
    gap: 12px;
}

.row-btn {
    min-width: 29px;
}

.clickable {
    cursor: pointer;
}

.cell-input {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.cell-input:focus {
    background-color: white;
    border: 2px solid $bg-table-row-selected;
}
