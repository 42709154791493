@import '../../variables';

.page-container{
    width: 100%;
    height: 100%;
    padding: 3% 10% 3% 10%;
    display: flex;
    flex-direction: column;
}
.table{
    width: 100%;
    height: 300px;
}