@import '../../variables';
 
.main-search-bar{ 
    margin-top: 0px;  
    display: flex;
    height: fit-content; 
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.operations-buttons{
    display: flex;
    margin-right: 15px;
    gap: 10px;
}
.table{
    margin-top: 20px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}
.periode{
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 10px;
}
.mantant-chargers{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}