@import '../../../variables';

.form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    row-gap: 35px;
}

.submit-btn {
    margin: 0 auto;
    display: flex;
    justify-content: end;
    width: 90%;
    margin-top: 75px;
}
