@import '../../variables';

.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100vw;
    height: $header-height;    
    background-color: $bg-color-primary;
    z-index: 999;
    padding: 5px 0;
}

.logo {
    margin-left: 12px;
    margin-right: 12px;
    height: 100%;
}

.links-profile-gate {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 15px;
}

.links {
    display: flex;
    height: 100%;
}
