@import '../../variables';

.article-container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}
.head-container{
    background: linear-gradient(0deg, $grad-main-color-1, $grad-main-color-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

}
.page-header{
    display: flex;
    align-items: center;
    gap: 3px;
}
.body-container{
    display: flex;
    flex-direction: column;
    background: white;
    min-height: 90vh;
    padding-bottom: 10vh;
    width: 100%;
    box-shadow: 0px 0px 1px black;
}
.header-title{
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.cancel:hover{
    cursor: pointer;
}
