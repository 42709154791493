@import '../../variables';
.body-container{
    height:93%;
    display: grid;
    grid-template-columns: $main-body-width $side-body-width;
    justify-content: space-between;
}
.actions-container{ 
    margin-top: 20px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    gap: 1.5rem;
}
.row-evenly{
    width:100%;
    //height:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.row-end{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
}
.col-around{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.col-evenly{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 100%;
}
.row-between{
    width:100%;
    height:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.noDisplay{
    width:100%;
    display: flex;
    align-items: center;
    gap: 20px;
    visibility: hidden;
}