

.body-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.page-title{
    font-size: 36px;
    font-weight: 600;
    color: black;
    margin-left: 20px;
}
.lines-container{
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    width: 100%;
    height: 100%;
    gap: 30px;
}
.line-container{
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    gap: 20px;
}
.text{
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-left: 20px;
}
.button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 90px;
}