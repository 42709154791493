@import '../../variables';

.body-container{
    height:fit-content;
    width: 100%;
    min-width: 90%;
    padding: 2% 10% 8% 8%;
}

.main-body{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%; 
}
.row-around{
    width:100%;
    // height:40%;
    display: grid;
    justify-content: start;
    align-items: center;
    grid-template-columns: 25% 74%;
    gap:1%;
}
.grid-space-between{
    display:flex;
    justify-content: space-between;
}
.grid-line-1-5{
    grid-column-start:1;
    grid-column-end:5;
}