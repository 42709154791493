@import '../../variables';

.ajouter-fournisseur-container{
    display: flex;
    flex-direction: column;
    padding: 3% 20% 5% 20%;
}
.ajouter-fournisseur{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ajouter-fournisseur-1-and-3-line{
    gap: 5%;
    display: flex;
}
.ajouter-fournisseur-4-and-5-line{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.input-1{
    gap: 5%;
    display: flex;
}
.ajouter-fournisseur-operation-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
}
.ajouter-fournisseur-operation{
    display: flex;
    gap: 10px;
}