@import '../../variables';


.ajouter-client-container{ 
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4% 20% 4% 20%;
}
.ajouter-client{
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 25px;
}
.ajouter-client-line-1{
    display: flex;
    gap: 5%;
    justify-content: space-evenly;
}
.ajouter-client-operation-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
}
.ajouter-client-operation{
    display: flex;
    gap: 10px;
}