@import '../../variables';
.row-around{
    position: relative;
    width:100%;
    height:40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}
.side-bar {
    padding:10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
}
.side-bar-informations{
    padding: 1rem 1rem 3rem 1rem;
    background-color: #E1EFF6;
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    border-radius: 4px;
    //border style
    border: 1px solid transparent;
    border-image: linear-gradient(90deg, $grad-main-color-1, $grad-main-color-2);
    border-image-slice: 1;  
}