@import '../../variables';
.body-container{
    height:93%;
    display: grid;
    grid-template-columns: $main-body-width $side-body-width;
}
.actions-container{ 
    height: fit-content;
    margin:20px 0 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    gap: 1.5rem;
}
.row{
    width:100%;
    height:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}