@import '../../variables';

.main-search-bar{
    margin-top: 15px;
    display: flex; 
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.operations-buttons{
    display: flex;
    margin-right: 15px;
    gap: 10px;
}
.table{
    margin-top: 2rem;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 450px;
}