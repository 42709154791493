@import '../../variables';

.container {
    height: 100%;
    width: 250px;
    border: 1px solid $border-color1;
    border-radius: 22px;
    position: relative;
    z-index: 999;
    display: flex;
    align-items: center;
    background-color: $bg-color-primary;
}

.container * {
    pointer-events: none;
}

.profile-pic {
    height: 80%;
    margin: 0 10px 0 7px;
}

.profile-name-role {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.profile-role {
    font-weight: 600;
    margin-top: 2px;
}

.dropdown {
    position: relative;
    background-color: $bg-color-primary;
    border: 1px solid $border-color1;
    width: 248px;
    top: -20px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-2;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 20px;
}

.dropdown button {
    height: 48px;
    width: 100%;
}

.dropdown button:hover {
    background-color: $bg-menubar-color1;
}

.hide {
    visibility: hidden;
    pointer-events: none;
}

.show {
    visibility: visible;
    pointer-events: initial;
}
