@import '../../_variables.scss';

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}  

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 7%;
}

.loader-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 14%;
}

.loading-icon {
    width: 70%;
    max-height: 138px;
    animation: spin 2s linear infinite;
}

.app-icon {
    position: absolute;
}

.message {
  font-size: 3.6rem;
  color: $text-color-primary;
  font-weight: 600;
}
