@import '../../variables';

.container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    background-color: $bg-color-primary;
}

.item {
    font-size: 1rem;
    width: 100%;
    padding: 0 5px;
    cursor: pointer;
}   

.item:hover {
    background-color: #ededed;
}

.list-indicator {
    margin-right: 7px;
}

.dropdown {
    position: absolute;
    min-width: 300px;
    background-color: $bg-color-primary;
    z-index: 1000;
    padding: 5px;
    border: 1px solid $divider-color-1;
}

.dropdown .added-vertical-padding {
    padding-top: 7px;
    padding-bottom: 7px;
}

.stick-to-left-bottom {
    left: 0;
    top: 100%;
}

.go-to-right-top {
    left: 100%;
    top: 0;
}

.divider {
    width: 100%;
    background-color: $divider-color-1;
    height: 1px;
    opacity: 0.7;
    margin-top: 5px;
    margin-bottom: 5px;
}

.section-header {
    font-size: 1rem;
    font-weight: 700;
    color: $text-color-third;
    padding: 3px;
    margin-top: 3px;
    margin-bottom: 5px;
}
