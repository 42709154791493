@import '../../variables';

.mod-item-name{
    font-size: 17px;
    position: relative;
    font-weight: 600;
    color: $text-color-secondary;
    top: 0;
    left: 0;
    padding-left: 7px;
    padding-top: 5px;
    padding-right: 7px;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.mod-item-name:hover{
    background-image: linear-gradient(90deg, $grad-main-color-1, $grad-main-color-2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
.mod-item-name-seconde{
    font-size: 17px;
    position: relative;
    font-weight: 600;
    color: $text-color-secondary;
    top: 0;
    left: 0;
    padding-left: 7px;
    padding-top: 5px;
    padding-right: 7px;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.mod-item-name-seconde:hover{
    background-image: linear-gradient(90deg, $grad-secondary-color-1, $grad-secondary-color-2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
.mod-item-container{
    width: 116px;
    height: 116px;
}