@import '../../variables';

.header-container{
    width: 100%;
    height: 100%;
    padding: 2% 10% 2% 10%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    height: fit-content;
}
.input-container{
    width: 50%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.operations-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    height: fit-content;
}
.sub-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: fit-content;
}
.validation-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
}
.ajouter-caisse{
    display: flex;
    gap: 10px;
}
.noDisplay{
    display: none;
}