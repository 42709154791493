@import '../../variables';

.custom-button{
    display: flex;  
    border: 2px solid $main-color;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 10px;
    gap: 4px;
    width: 100%;
    height: 50px;
}
.custom-button-cancel{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 50px;
    background-color: #e7e7e7;
    font-weight: 600;
    border-radius: 4px;
    padding: 10px;
    border: 2px solid #e7e7e7;
}
.cancel-text{
    font-size: 16px;
    color: black;
    text-align: center;
    width: 100%;
    height: 100%;
}
.custom-button-cancel-container{
    height: 100%;
}
.custom-button:hover{
    background: linear-gradient(0deg, $grad-main-color-1, $grad-main-color-2);
}
.button-name-main{
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 100%;
    width: fit-content;
    color: $main-color;
    font-weight: 600;
}
.button-name-secondary{
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 100%;
    width: fit-content;
    color: $text-color-secondary;
    font-weight: 600;
}
.button-name:hover{
    color: $text-color-secondary;
}

