@import '../../variables';
.body-container{
    padding: 2% 10% 2% 10%;
}
.main-body{ 
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.row-end{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
}
.row-between{
    width:100%;
    height:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.row{
    display: flex;
    align-items: center;
    align-items: center;
    gap: 1rem;
}