@import '../../variables';

.background{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.dialog-container{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    padding: 30px 25px 30px 25px;
    border-radius: 10px;
    width: 500px;
    height: fit-content;
    position: absolute;
    left: 35%;
    right: 40%;
    top: 38%;
    bottom: 40%;
}

.operation-container{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
}

.message-container{
    font-size: 17px;
    color: black;
    font-weight: 600;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}