.container {
    padding: 16px;
}

.fill-width {
    width: 100%;
}

.upper-section {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.print-export {
    display: flex;
    gap: 10px;
}
