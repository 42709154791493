@import '../../variables';

.container {
    background-color: #E1EFF6;
    min-width: 238px;
    height: 252px;
    border-radius: $border-radius-2;
    padding: 10px;
}

.profile-pic {
    width: 56px;
    margin: 6px 0 7px 10px;
}

.name {
    font-weight: 600;
    font-size: 0.96em;
    margin-left: 9px;
    margin-bottom: 3px;
}

.role {
    font-size: 0.92em;
    color: $text-color-third;
    margin-left: 10px;
    margin-bottom: 7px;
    font-weight: 500;
}

.card-details {
    background-color: $bg-color-primary;
    border-radius: $border-radius-2;
    height: 118px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hire-date {
    display: flex;
    justify-content: space-between;
    // font-weight: 500;
}

.hire-date :first-child {
    color: $text-color-third;
    font-weight: 500;
}

.set-in-row {
    display: flex;
    column-gap: 10px;
    font-size: 0.96em;
    margin-top: 8px;
    // font-weight: 500;
}

.set-in-row :first-child {
    width: 1.25rem;
}
