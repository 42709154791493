@import '../../variables';

.body-container{
    height:fit-content;
    width: 100%;
    min-width: 90%;
    display: grid;
    grid-template-columns:75% 20%;
    padding: 2% 8% 2% 8%;
    gap: 5%;
}
.main-body{
    display: grid;
    grid-template-rows: repeat(6 , auto);
    gap: 1rem;
    height: 100%; 
}
.side-body{
    height: 100%;
    display:grid;
    grid-template-rows: 30% 50%;
    gap:3%;
}
.row-around{
    position: relative;
    width:100%;
    height:40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.cancel-validate {
    margin-top: 24px;
}

.addButton{
    grid-column-start: 1;
    grid-column-end:4 ;
    height: fit-content;
}
.addAndDelete{
    display: grid;
    grid-column-start: 1;
    grid-column-end:4 ;
    height: fit-content;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}
.drop-field{
    margin : 21px 0 0 0 ;
    border: 1px dashed $main-color;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius:$border-radius-2;
}
.article-field{
    border:1px dashed $main-color;
    width: 100%;
    padding: 15px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:$border-radius-2;
}
// .article-field >* {
//     color:$text-color-third;
// }
.boldy{
    font-weight: bold;
    text-align: center;
}
.image{
    width: 25%;
    height: 25%;
}
// .parcourer{
//     padding-left:5px;
//     color:$main-color;
//     font-weight: bold;
//     text-decoration: underline;
// }
.faint-text{
    color:$text-color-third;
    font-size: 12px;
    text-align: center;
}